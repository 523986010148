import { reactive } from 'vue'

export function formDataLotCreate (isBidder) {
  const formData = reactive({
    // Основные параметры
    started_at: '',
    duration: '',
    extension_time: '00:10',
    step_time: '00:10',
    note: '',

    // Параметры ТП с понижением цены
    start_price: 0,
    min_step: null,
    max_step: null,
    is_procent: true,

    // Параметры лота
    name: '',
    divisibility: 0,
    company_id: null,
    published_at: new Date(),
    isPublishedBeforeSave: false,

    // Условия поставки
    delivery_condition_id: null,
    construction_start_at: null,
    construction_finish_at: null,
    construction_special_condition_id: null,
    construction_criteria_execution_id: null,
    construction_term_contract_id: null,
    delivery_condition_mandatory: false, // change
    delivery_address: null,
    delivery_frequency: '',
    delivery_ability_edit: false,

    // Условия оплаты
    currency_id: 1,
    payment_term_id: null,
    vat_requirement: false,
    payment_ability_edit: false,
    isNds: true,
    nds_counter: '20',

    // Сопроводительная документация
    supportingDocumentation: [],

    // Критерии Лот
    table_criterion: reactive([]),
    is_criterion_payment: false,
    is_criterion_delivery: false,
    is_criterion_valute: false,
    is_criterion_deley_max: false,

    // Позиции
    positions: reactive([]),

    // Тип торговый процедуры
    procedure_type_id: 1,

    analog_acceptable: false,
    responsible_phone: '',
    phone_extension: '',
    responsible_full_name: '',
    order_number: '',

    criterion_value: '1',
    criterion_non_value: '0',
    criterion_skill: '0',

    categories: [],
    category_ids: [],

    deley_min: '0',
    deley_max: '60'
  })
  if (isBidder) {
    formData.relation_to_products_id = ''
  }
  return formData
}
